import { BrowserRouter as Router, Link } from 'react-router-dom';
import AppRoutes from './Routes';
import { useState } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import DrawerMenu from './components/Drawer';
import './App.css';
/* eslint-disable */


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Router>
      <div className="container">
        {isAuthenticated && (
          <>
            <Header toggleDrawer={toggleDrawer(true)} />
            <DrawerMenu open={drawerOpen} toggleDrawer={toggleDrawer} setIsAuthenticated={setIsAuthenticated} />
          </>
        )}

        <main className="content">
          <AppRoutes isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
        </main>

        {isAuthenticated && <Footer />}
      </div>
    </Router>
  );
}

export default App;

import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './page/Dashboard';
import Login from './page/Login';
import GamePage from './page/GamePage';
import Loading from './page/Loading';
import GameCompleted from './page/GameCompleted'; // Import the GameCompleted component

function AppRoutes({ isAuthenticated, setIsAuthenticated }) {
  const ProtectedRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/login" />;
  };

  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute element={<Dashboard />} />} />
      <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
      
      {/* Add loading route */}
      <Route
        path="/loading"
        element={<ProtectedRoute element={<Loading />} />}
      />

      {/* Add game page route */}
      <Route
        path="/page/:pageId"
        element={<ProtectedRoute element={<GamePage />} />}
      />

      {/* Add game completed route */}
      <Route
        path="/game-completed"
        element={<ProtectedRoute element={<GameCompleted />} />}
      />

      {/* Fallback for undefined routes */}
      <Route path="*" element={<h1>404 - Halaman Tidak Ditemukan</h1>} />
    </Routes>
  );
}

export default AppRoutes;

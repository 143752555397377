import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const GameList = ({ image, title, pageId }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/loading', { state: { pageId,image } });
  };

  

  return (
    <Card>
      <CardActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          height="140"
          image={image}
          alt={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
            {pageId}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default GameList;

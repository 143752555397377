import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Card, CardContent, Avatar, Grid } from '@mui/material';
import data from '../data.json'; // Ensure the path is correct

const GameCompleted = () => {
  const location = useLocation();
  const { pageId } = location.state || {}; // Get the pageId from location state

  // Access the games array from data.json and find the correct game
  const game = data.games ? data.games.find((g) => g.id === pageId) : null;

  if (!game) {
    return (
      <Box sx={{ padding: 4 }}>
        <Typography variant="h6">Game not found.</Typography>
      </Box>
    );
  }

  // Sample user and opponent data; replace this with actual data as needed
  const user = {
    name: "User",
    image: "https://via.placeholder.com/120",
  };
  
  const opponent = {
    name: "Opponent",
    image: "https://via.placeholder.com/120",
  };

  return (
    <Box sx={{ padding: 4 }}>
      {/* Section 1: Game Details */}
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5">{game.title}</Typography>
          <img
            src={game.image}
            alt={game.title}
            
            style={{ width: '100%', maxWidth: '300px', borderRadius: '8px' }}
          />
         
          <Typography variant="h6" mt={2}>
            Game Completed!
          </Typography>
        </CardContent>
      </Card>

      {/* Section 2: Winning Information */}
      <Typography variant="h6" gutterBottom>
        Who's Winning?
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} textAlign="center">
          <Avatar src={user.image} alt="User" sx={{ width: 120, height: 120, margin: 'auto' }} />
          <Typography>{user.name}</Typography>
        </Grid>
        <Grid item xs={6} textAlign="center">
          <Avatar src={opponent.image} alt="Opponent" sx={{ width: 120, height: 120, margin: 'auto' }} />
          <Typography>{opponent.name}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameCompleted;

import React,{useState,useEffect} from 'react';
import { AppBar, Toolbar, IconButton, Typography} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FavoriteIcon from '@mui/icons-material/Favorite';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';

import AccessTimeIcon from '@mui/icons-material/AccessTime';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState(3600);
  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Box
      sx={{
        top: 10,
        right: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between',
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Box sx={{display: 'flex'}}>
      <FavoriteIcon sx={{ color: 'red', mr: 1 }} />
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        x 5
      </Typography>
      </Box>
      <Box sx={{display: 'flex'}}>
      <AccessTimeIcon sx={{ mr: 1 }} />
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {formatTime(timeLeft)} remaining time
      </Typography>
      </Box>
    </Box>
  );
};

const Header = ({ toggleDrawer }) => {
  return (
    <AppBar position="fixed">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          My App
        </Typography>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
      </Toolbar>
      <CountdownTimer/>
    </AppBar>
  );
};

export default Header;

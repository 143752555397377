import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Game from '../components/Game';

const GamePage = () => {
  const { pageId } = useParams();
  const navigate = useNavigate();

  const handleFinish = () => {
    // Navigate to the GameCompleted page and pass necessary game data
    navigate('/game-completed', { state: { pageId } });
  };

  return (
    <div>
      <Game />
      <button onClick={handleFinish}>Finish</button>
    </div>
  );
};

export default GamePage;

import React from 'react';

const Game = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <iframe
        src="/masterGame/ayam/index.html"
        width="800"
        height="480"
        style={{ border: 'none' }}
        title="Game Iframe"
      />
    </div>
  );
};

export default Game;

import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SearchIcon from '@mui/icons-material/Search';
import Bell from '@mui/icons-material/NotificationsActive';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
    >
      <BottomNavigationAction
        component={Link}
        to="/"
        label="Home"
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/about"
        label="About"
        icon={<InfoIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/favorite"
        label="Favorite"
        icon={<FavoriteIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/search"
        label="Search"
        icon={<SearchIcon />}
      />
      <BottomNavigationAction
        component={Link}
        to="/notification"
        label="Notification"
        icon={<Bell />}
      />
    </BottomNavigation>
  );
};

export default Footer;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Games from '../components/GameList';
import data from '../data.json';

export default function Dashboard() {
  const [cards, setCards] = useState([]);
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    setCards(data.games);
  }, []);

  const handleGameClick = (card) => {
    navigate('/loading', {
      state: {
        pageId: card.id, // Pass the game ID (pageId)
        image: card.image, // You can pass other necessary data like the image
      },
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography>
          <Box sx={{ fontSize: 25, m: 1, mt: 15 }}>
            Welcome, <b>User</b>
          </Box>
        </Typography>

        <Grid container spacing={2} mt={4} mb={12} justifyContent="center">
          {cards.map((card) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={card.id}
              sx={{
                width: 175,
                height: 225,
                display: 'flex',
                justifyContent: 'left',
                borderRadius: 3,
                cursor: 'pointer', // Add pointer cursor to indicate clickability
              }}
              onClick={() => handleGameClick(card)} // Handle click event
            >
              <Games image={card.image} title={card.title} link={card.link} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Avatar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import data from '../data.json'; // Adjust the path as necessary

const Loading = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageId, image } = location.state || {};
  
  const [showOpponentFound, setShowOpponentFound] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [opponent, setOpponent] = useState(null);

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      setCountdown((prev) => {
        if (prev === 1) {
          clearInterval(countdownTimer);
          selectRandomOpponent(); // Select a random opponent when countdown ends
          setShowOpponentFound(true);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, []);

  const selectRandomOpponent = () => {
    const randomIndex = Math.floor(Math.random() * data.opponents.length);
    setOpponent(data.opponents[randomIndex]);
  };

  const handlePlayGame = () => {
    if (pageId) {
      navigate(`/page/${pageId}`);
    } else {
      console.error('Page ID is missing');
    }
  };

  const handleClose = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="start"
      height="85vh"
      sx={{
        backgroundColor: '#f5f5f5',
        padding: 4,
        pt: 11,
      }}
    >
      {!showOpponentFound && (
        <Grid container alignItems="center" justifyContent="space-between" sx={{ my: 3, width: '100%' }}>
          <Grid item>
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                fontWeight: 500,
              }}
            >
              Search Remaining: {countdown} seconds
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleClose}
              aria-label="Close"
              sx={{
                borderRadius: '50%',
                minWidth: '40px',
                minHeight: '40px',
                p: 0,
                color: 'secondary.main',
                border: '1px solid',
                borderColor: 'secondary.main',
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      )}
      {!showOpponentFound ? (
        <Box textAlign="center" mt={4}>
          <Typography variant="h4" gutterBottom>
            Finding Best Opponent for You
          </Typography>
          {image && (
            <Box mt={2}>
              <img
                src={image}
                alt="Selected Game"
                style={{ width: '100%', maxWidth: '300px', borderRadius: '12px' }}
              />
            </Box>
          )}
        </Box>
      ) : (
        <Box textAlign="center" mt={4}>
          <Grid container justifyContent="flex-end" sx={{ mt: 2, width: '100%' }}>
            <Button
              onClick={handleClose}
              aria-label="Close"
              sx={{
                borderRadius: '50%',
                minWidth: '40px',
                minHeight: '40px',
                p: 0,
                color: 'secondary.main',
                border: '1px solid',
                borderColor: 'secondary.main',
              }}
            >
              <CloseIcon />
            </Button>
          </Grid>
          {image && (
            <img
              src={image}
              alt="Selected Game"
              style={{ width: '100%', maxWidth: '150px', borderRadius: '12px' }}
            />
          )}
          <Typography variant="h4" gutterBottom>
            Matched Opponent Found!
          </Typography>
          {opponent && (
            <>
              <Avatar
                src={opponent.image}
                alt="Opponent"
                sx={{ width: 120, height: 120, margin: '20px auto' }}
              />
              <Typography variant="h6" gutterBottom>
                <strong>{opponent.name}</strong>
              </Typography>
              <Typography variant="body1">
                User, we’ve found the perfect match for you!
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Proceed to play and compete with <strong>{opponent.name}</strong> to win a prize!
              </Typography>
            </>
          )}
          <Box mt={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePlayGame}
              sx={{ px: 4, py: 1.5, fontSize: '16px' }}
            >
              Play Now
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Loading;
